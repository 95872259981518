import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BRAND_NAME, DOMAIN_NAME } from "../util/constants";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <section className="section">
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
          <p>
            <em>Last Updated: September 7, 2019</em>
          </p>
          <p>
            This Privacy Policy applies to information that we collect about you
            when you use:
          </p>
          <ul>
            <li>
              Our websites (including {`${DOMAIN_NAME} `} and splatboard.com);
            </li>
            <li>
              Our TV, mobile, and native applications (including the{" "}
              {`${BRAND_NAME} `}
              Display app for Android and iOS).
            </li>
          </ul>
          <p>
            Throughout this Privacy Policy we’ll refer to our websites,
            applications, and other products and services collectively as
            “Services.”
          </p>
          <p>
            Below we explain how we collect, use, and share information about
            you, along with the choices that you have with respect to that
            information.
          </p>
          <h2>Information We Collect</h2>
          <p>
            We only collect information about you if we have a reason to do so -
            for example, to provide our Services, to communicate with you, or to
            make our Services better.
          </p>
          <p>
            We collect information in three ways: if and when you provide
            information to us, automatically through operating our Services, and
            from outside sources. Let’s go over the information that we collect.
          </p>
          <h3>Information You Provide to Us</h3>
          <p>
            It’s probably no surprise that we collect information that you
            provide to us. The amount and type of information depends on the
            context and how we use the information. Here are some examples:
          </p>
          <ul>
            <li>
              Basic Account Information: We ask for basic information from you
              in order to set up your account. For example, we require
              individuals who sign up for a {`${BRAND_NAME} `} account to
              provide an email address along with some information about their
              planned usage.
            </li>
            <li>
              Public Profile Information: If you have an {`${BRAND_NAME} `}{" "}
              account, we collect the information that you provide for your
              public profile, such as your venue's location and description.
              Your public profile information is just that — public — so please
              keep that in mind when deciding what information you would like to
              include.
            </li>
            <li>
              Transaction and Billing Information: If you buy something from us
              – for example, a subscription to a {`${BRAND_NAME} `} plan - you
              will provide additional personal and payment information that is
              required to process the transaction and your payment, such as your
              name, credit card information, and contact information.
            </li>
            <li>
              Content Information: You may also provide us with information
              about you in draft and published content. For example, if you add
              a {`${BRAND_NAME} `} menu item that includes biographic
              information about you, we will have that information, and so will
              anyone with access to the Internet if you choose to publish the
              post publicly.
            </li>
            <li>
              Communications with Us (Hi There!): You may also provide us
              information when you respond to surveys, communicate with our
              support team, post a question about your site in our public
              forums, or sign up for a newsletter. When you communicate with us
              via form, email, phone, forum comment, or otherwise, we store a
              copy of our communications.
            </li>
          </ul>
          <h3>Information We Collect Automatically</h3>
          <p>We also collect some information automatically:</p>
          <ul>
            <li>
              Log Information: Like most online service providers, we collect
              information that web browsers, mobile devices, and servers
              typically make available, such as the browser type, IP address,
              unique device identifiers, language preference, referring site,
              the date and time of access, operating system, and network
              information. We collect log information when you use our
              Services–for example, when you create or make changes to your
              account on {`${BRAND_NAME}`}.
            </li>
            <li>
              Usage Information: We collect information about your usage of our
              Services. For example, we collect information about the actions
              that team owners, managers, and users perform on a location using
              the {`${BRAND_NAME} `} dashboard – in other words, who did what,
              when and to what thing. We also collect information about what
              happens when you use our Services (e.g., page views, support
              document searches, features enabled for your account, interactions
              with our support team, and other parts of our Services) along with
              information about your device (e.g., screen size, name of network,
              and mobile device manufacturer). We use this information to, for
              example, provide our Services to you, as well as get insights on
              how people use our Services, so we can make our Services better.
            </li>
            <li>
              Location Information: We may determine the approximate location of
              your device from your IP address. We collect and use this
              information to, for example, calculate how many people visit or
              use our Services from certain geographic regions.
            </li>
            <li>
              Stored Information: We may access information stored on your TV or
              mobile device via our applications. We access this stored
              information through your device operating system’s permissions.
              For example, if you give us permission to access the photographs
              on your mobile device’s camera roll, our Services may access the
              photos stored on your device when you upload a really amazing
              photograph of the sunrise to your website.
            </li>
            <li>
              Information from Cookies &amp; Other Technologies: A cookie is a
              string of information that a website stores on a visitor’s
              computer, and that the visitor’s browser provides to the website
              each time the visitor returns. Pixel tags (also called web
              beacons) are small blocks of code placed on websites and emails.
              Splatboard uses cookies and other technologies like pixel tags to
              help us identify and track visitors, usage, and access preferences
              for our Services, as well as track and understand email campaign
              effectiveness and to deliver targeted ads. For more information
              about our use of cookies and other technologies for tracking,
              including how you can control the use of cookies, please see our
              Cookie Policy.
            </li>
          </ul>
          <h3>Information We Collect from Other Sources</h3>
          <p>
            We may also get information about you from other sources. For
            example, if you create or log into your {`${BRAND_NAME} `} account
            through another service (like Google) or if you connect your account
            to a social media service (like Twitter), we will receive
            information from that service (such as your username, basic profile
            information, and friends list) via the authorization procedures used
            by that service. The information we receive depends on which
            services you authorize and any options that are available.
          </p>
          <h2>How and Why We Use Information</h2>
          <h3>Purposes for Using Information</h3>
          <p>
            We use information about you as mentioned above and for the purposes
            listed below:
          </p>
          <ul>
            <li>
              To provide our Services–for example, to set up and maintain your
              account, host your website, backup and restore your website,
              charge you for any of our paid Services;
            </li>
            <li>
              To further develop and improve our Services–for example, by adding
              new features that we think our users will enjoy or will help them
              to create and manage their teams more efficiently;
            </li>
            <li>
              To monitor and analyze trends and better understand how users
              interact with our Services, which helps us improve our Services
              and make them easier to use;
            </li>
            <li>
              To measure, gauge, and improve the effectiveness of our
              advertising, and better understand user retention and attrition —
              for example, we may analyze how many individuals purchased a paid
              plan after receiving a marketing message or the features used by
              those who continue to use our Services after a certain length of
              time;
            </li>
            <li>
              To monitor and prevent any problems with our Services, protect the
              security of our Services, detect and prevent fraudulent
              transactions and other illegal activities, fight spam, and protect
              the rights and property of Splatboard and others, which may result
              in us declining a transaction or the use of our Services;
            </li>
            <li>
              To communicate with you, for example through an email, about
              offers and promotions offered by Splatboard and others we think
              will be of interest to you, solicit your feedback, or keep you up
              to date on Splatboard and our products (which you can unsubscribe
              from at any time); and
            </li>
            <li>
              To personalize your experience using our Services, provide content
              recommendations, target our marketing messages to groups of our
              users (for example, those who have a particular plan with us or
              have been our user for a certain length of time), and serve
              relevant advertisements.
            </li>
          </ul>
          <h3>Legal Bases for Collecting and Using Information</h3>
          <p>
            A note here for those in the European Union about our legal grounds
            for processing information about you under EU data protection laws,
            which is that our use of your information is based on the grounds
            that:
          </p>
          <ol>
            <li>
              The use is necessary in order to fulfill our commitments to you
              under the applicable terms of service or other agreements with you
              or is necessary to administer your account — for example, in order
              to enable access to our website on your device or charge you for a
              paid plan; or
            </li>
            <li>
              The use is necessary for compliance with a legal obligation; or
            </li>
            <li>
              The use is necessary in order to protect your vital interests or
              those of another person; or
            </li>
            <li>
              We have a legitimate interest in using your information — for
              example, to provide and update our Services; to improve our
              Services so that we can offer you an even better user experience;
              to safeguard our Services; to communicate with you; to measure,
              gauge, and improve the effectiveness of our advertising; and to
              understand our user retention and attrition; to monitor and
              prevent any problems with our Services; and to personalize your
              experience; or
            </li>
            <li>
              You have given us your consent — for example before we place
              certain cookies on your device and access and analyze them later
              on, as described in our Cookie Policy.
            </li>
          </ol>
          <h2>Sharing Information</h2>
          <h3>How We Share Information</h3>
          <p>We do not sell our users’ private personal information.</p>
          <p>
            We share information about you in the limited circumstances spelled
            out below and with appropriate safeguards on your privacy:
          </p>
          <ul>
            <li>
              Subsidiaries, Employees, and Independent Contractors: We may
              disclose information about you to our subsidiaries, our employees,
              and individuals who are our independent contractors that need to
              know the information in order to help us provide our Services or
              to process the information on our behalf. We require our
              subsidiaries, employees, and independent contractors to follow
              this Privacy Policy for personal information that we share with
              them.
            </li>
            <li>
              Third Party Vendors: We may share information about you with third
              party vendors who need to know information about you in order to
              provide their services to us, or to provide their services to you
              or your site. This group includes vendors that help us provide our
              Services to you (like payment providers that process your credit
              and debit card information, payment providers you use for your
              ecommerce operations, fraud prevention services that allow us to
              analyze fraudulent payment transactions, postal and email delivery
              services that help us stay in touch with you, and customer chat
              and email support services that help us communicate with you),
              those that assist us with our marketing efforts (e.g. by providing
              tools for identifying a specific marketing target group or
              improving our marketing campaigns), and those that help us
              understand and enhance our Services (like analytics providers). We
              require vendors to agree to privacy commitments in order to share
              information with them.
            </li>
            <li>
              Legal and Regulatory Requirements: We may disclose information
              about you in response to a subpoena, court order, or other
              governmental request.
            </li>
            <li>
              To Protect Rights, Property, and Others: We may disclose
              information about you when we believe in good faith that
              disclosure is reasonably necessary to protect the property or
              rights of Splatboard, third parties, or the public at large. For
              example, if we have a good faith belief that there is an imminent
              danger of death or serious physical injury, we may disclose
              information related to the emergency without delay.
            </li>
            <li>
              Business Transfers: In connection with any merger, sale of company
              assets, or acquisition of all or a portion of our business by
              another company, or in the unlikely event that Splatboard goes out
              of business or enters bankruptcy, user information would likely be
              one of the assets that is transferred or acquired by a third
              party. If any of these events were to happen, this Privacy Policy
              would continue to apply to your information and the party
              receiving your information may continue to use your information,
              but only consistent with this Privacy Policy.
            </li>
            <li>
              With Your Consent: We may share and disclose information with your
              consent or at your direction. For example, we may share your
              information with third parties with which you authorize us to do
              so, such as social media services that you may connect to your
              account.
            </li>
            <li>
              Aggregated or De-Identified Information: We may share information
              that has been aggregated or reasonably de-identified, so that the
              information could not reasonably be used to identify you. For
              instance, we may publish aggregate statistics about the use of our
              Services.
            </li>
            <li>
              Published Support Requests: And if you send us a request (for
              example, via a support email or one of our feedback mechanisms),
              we reserve the right to publish that request in order to help us
              clarify or respond to your request or to help us support other
              users.
            </li>
          </ul>
          <h3>Information Shared Publicly</h3>
          <p>
            Information that you choose to make public is–you guessed
            it–disclosed publicly.
          </p>
          <p>
            That means, of course, that information like your team's public
            website, forums posts, and other content that you make public on
            your team page and displays, are all available to others.
          </p>
          <p>
            Public information may also be indexed by search engines or used by
            third parties.
          </p>
          <p>
            Please keep all of this in mind when deciding what you would like to
            share.
          </p>
          <h2>How Long We Keep Information</h2>
          <p>
            We generally discard information about you when we no longer need
            the information for the purposes for which we collect and use it —
            which are described in the section above on How and Why We Use
            Information — and we are not legally required to continue to keep
            it.
          </p>
          <h2>Security</h2>
          <p>
            While no online service is 100% secure, we work very hard to protect
            information about you against unauthorized access, use, alteration,
            or destruction, and take reasonable measures to do so, such as
            monitoring our Services for potential vulnerabilities and attacks.
          </p>
          <h2>Choices</h2>
          <p>
            You have several choices available when it comes to information
            about you:
          </p>
          <ul>
            <li>
              Limit the Information that You Provide: If you have an account
              with us, you can choose not to provide the optional account
              information, profile information, and transaction and billing
              information. Please keep in mind that if you do not provide this
              information, certain features of our Services — for example, paid,
              premium themes–may not be accessible.
            </li>
            <li>
              Limit Access to Information on Your Mobile Device: Your mobile
              device operating system should provide you with the ability to
              discontinue our ability to collect stored information or location
              information via our mobile apps. If you do so, you may not be able
              to use certain features (like adding a location to a photograph,
              for example).
            </li>
            <li>
              Opt-Out of Marketing Communications: You may opt out of receiving
              promotional communications from us. Just follow the instructions
              in those communications or let us know. If you opt out of
              promotional communications, we may still send you other
              communications, like those about your account and legal notices.
            </li>
            <li>
              Set Your Browser to Reject Cookies: At this time, Splatboard does
              not respond to “do not track” signals across all of our Services.
              However, you can usually choose to set your browser to remove or
              reject browser cookies before using Splatboard's websites, with
              the drawback that certain features of Splatboard's websites may
              not function properly without the aid of cookies.
            </li>
            <li>
              Close Your Account: While we’d be very sad to see you go, if you
              no longer want to use our Services, you can close your account.
              Please keep in mind that we may continue to retain your
              information after closing your account, as described in How Long
              We Keep Information above — for example, when that information is
              reasonably needed to comply with (or demonstrate our compliance
              with) legal obligations such as law enforcement requests, or
              reasonably needed for our legitimate business interests.
            </li>
          </ul>
          <h2>Privacy Policy Changes</h2>
          <p>
            Although most changes are likely to be minor, Splatboard may change
            its Privacy Policy from time to time. Splatboard encourages visitors
            to frequently check this page for any changes to its Privacy Policy.
            If we make changes, we will notify you by revising the change log
            below, and, in some cases, we may provide additional notice (such as
            adding a statement to our homepage or Twitter account, or sending
            you a notification through email or your dashboard). Your further
            use of the Services after a change to our Privacy Policy will be
            subject to the updated policy.
          </p>
        </Col>
      </Row>
    </section>
  </Layout>
);

export default PrivacyPage;
